import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import PoliciesNavBar from '../../../components/section/policies/PoliciesNavBar';
import PoliciesContentArea from '../../../components/section/policies/PoliciesContentArea';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

const Page = () => (
  <Body header={header}>
    <SEO
      title="Security, Privacy and Architecture Documentation - Rotaready"
      url="policies/security-privacy-architecture"
    />

    <ResponsiveContainer>
      <ContentWrapper>
        <TitleWrapper>
          <H1 uistyle="brand160" text="Security, Privacy & Architecture Documentation" />
        </TitleWrapper>

        <BodyWrapper>
          <PoliciesNavBar />

          <PoliciesContentArea>
            <p><b>Last updated: 21 May 2018</b></p>

            <H2 uistyle="brand160">Rotaready's Corporate Trust Commitment</H2>

            <p>Rotaready is committed to achieving and maintaining the trust of our customers. Integral to this
              mission is providing a robust security and privacy program that carefully considers data protection
              matters across our suite of services, including data submitted by customers to our services ("<b>Customer
                Data</b>").</p>

            <H2 uistyle="brand160">Services Covered</H2>

            <p>This documentation describes the architecture of, the security- and privacy-related audits and
              certifications received for, and the administrative, technical and physical controls applicable to,
              the Rotaready platform.</p>

            <p>Some of the elements described in this documentation, such as log retention, back-ups, disaster
              recovery and return and deletion of data, do not apply to the temporary developer testing
              environments branded as "Sandboxes."</p>

            <H2 uistyle="brand160">Architecture and Data Segregation</H2>

            <p>The Covered Services are operated in a multitenant architecture that is designed to segregate and
              restrict Customer Data access based on business needs. The architecture provides an effective
              logical data separation for different customers via customer-specific "Organisation IDs" and allows
              the use of customer and user role-based access privileges. Additional data segregation is ensured by
              providing separate environments for different functions, especially for testing and production. The
              specific infrastructure used to host Customer Data is described in the <Link to="/policies/subprocessors">Sub-processors</Link> documentation.</p>

            <p>Additionally, the Covered Services undergo security assessments by internal personnel and third
              parties, which include infrastructure vulnerability assessments and application security
              assessments, on at least an annual basis.</p>

            <H2 uistyle="brand160">Control of Processing</H2>

            <p>Rotaready has implemented procedures designed to ensure that Customer Data is processed only as
              instructed by the customer, throughout the entire chain of processing activities by Rotaready and
              its sub-processors. In particular, Rotaready and its affiliates have entered into written agreements
              with their sub-processors containing privacy, data protection and data security obligations that
              provide a level of protection appropriate to their processing activities. Compliance with such
              obligations as well as the technical and organisational data security measures implemented by
              Rotaready and its sub-processors are subject to regular audits. The <Link to="/policies/subprocessors">Sub-processors</Link> documentation
              describes the sub-processors and certain other entities material to Rotaready's provision of the
              Covered Services.</p>

            <H2 uistyle="brand160">Security Controls</H2>

            <p>The Covered Services include a variety of configurable security controls that allow customers to
              tailor the security of the Covered Services for their own use. Please see additional information on
              such controls in the Rotaready Knowledge Base.</p>

            <H2 uistyle="brand160">Security Policies and Procedures</H2>

            <p>The Covered Services are operated in accordance with the following policies and procedures to enhance
              security:</p>

            <ul>
              <li>Customer passwords are stored using a one-way salted hash.</li>
              <li>User access log entries will be maintained, containing date, time, user ID, URL executed or
                entity ID operated on, operation performed (created, updated, deleted) and source IP address.
                Note that source IP address might not be available if NAT (Network Address Translation) or PAT
                (Port Address Translation) is used by the User or its ISP.
              </li>
              <li>If there is suspicion of inappropriate access, Rotaready can provide customers log entry records
                to assist in forensic analysis. This service will be provided to customers on a time and
                materials basis.
              </li>
              <li>Data centre physical access logs, system infrastructure logs, and application logs will be kept
                for a minimum of 30 days. Logs will be kept in a secure area to prevent tampering.
              </li>
              <li>Passwords are not logged.</li>
              <li>Rotaready personnel will not set a defined password for a user. Passwords are reset to a random
                value (which must be changed on first use) and delivered automatically via email to the
                requesting user.
              </li>
            </ul>

            <H2 uistyle="brand160">Intrusion Detection</H2>

            <p>Rotaready, or an authorised third party, will monitor the Covered Services for unauthorised
              intrusions using network-based and/or host-based intrusion detection mechanisms. Rotaready may
              analyse data collected by users' web browsers (e.g., device type, screen resolution, time zone,
              operating system version, browser type and version, system fonts, installed browser plug-ins,
              enabled MIME types, etc.) for security purposes, including to detect compromised browsers, to
              prevent fraudulent authentications, and to ensure that the Covered Services function properly.</p>

            <H2 uistyle="brand160">Security Logs</H2>

            <p>All systems used in the provision of the Covered Services, including firewalls, routers, network
              switches and operating systems, log information to their respective system log facility or a
              centralised syslog server (for network systems) in order to enable security reviews and
              analysis.</p>

            <H2 uistyle="brand160">Incident Management</H2>

            <p>Rotaready maintains security incident management policies and procedures. Rotaready notifies impacted
              customers without undue delay of any unauthorised disclosure of their respective Customer Data by
              Rotaready or its agents of which Rotaready becomes aware to the extent permitted by law.</p>

            <p>Rotaready typically notifies customers of significant system incidents by email, and for incidents
              lasting more than one hour, may invite impacted customers to join a conference call about the
              incident and Rotaready's response.</p>

            <H2 uistyle="brand160">User Authentication</H2>

            <p>Access to Covered Services requires authentication via one of the supported mechanisms, including
              user ID/password, SAML based Federation, Oauth, Social Login, or Delegated Authentication as
              determined and controlled by the customer. Following successful authentication, a random session ID
              is generated and stored in the user's browser to preserve and track session state.</p>

            <H2 uistyle="brand160">Physical Security</H2>

            <p>Production data centres used to provide the Covered Services have access control systems that permit
              only authorised personnel to have access to secure areas. These facilities are designed to withstand
              adverse weather and other reasonably predictable natural conditions, utilise redundant electrical
              and telecommunications systems, employ environmental systems that monitor temperature, humidity and
              other environmental conditions, and contain strategically placed heat, smoke and fire detection and
              suppression systems. Facilities are secured by around-the-clock guards, interior and exterior
              surveillance cameras, two-factor access screening and escort-controlled access. In the event of a
              power failure, uninterruptible power supply and continuous power supply solutions are used to
              provide power while transferring systems to on-site back-up generators.</p>

            <H2 uistyle="brand160">Reliability and Backup</H2>

            <p>All networking components, network accelerators, load balancers, Web servers and application servers
              are configured in a redundant configuration. All Customer Data submitted to the Covered Services is
              stored on a primary database server with multiple active clusters for higher availability. All
              Customer Data submitted to the Covered Services is stored on highly redundant carrier-class disk
              storage and multiple data paths to ensure reliability and performance. All Customer Data submitted
              to the Covered Services, up to the last committed transaction, is automatically replicated on a near
              real-time basis to the secondary site and is backed up on a regular basis and stored on backup media
              for an additional 30 days in production environments and 7 days in Sandbox environments, after which
              it is securely overwritten or deleted. Any backups are verified for integrity and stored in the same
              data centres as their instance.</p>

            <H2 uistyle="brand160">Disaster Recovery</H2>

            <p>Production data centres are designed to mitigate the risk of single points of failure and provide a
              resilient environment to support service continuity and performance. The Covered Services utilise
              secondary facilities that are geographically diverse from their primary data centres, along with
              required hardware, software, and Internet connectivity, in the event Rotaready production facilities
              at the primary data centres were to be rendered unavailable.</p>

            <p>Rotaready has disaster recovery plans in place and tests them at least once per year. The scope of
              the disaster recovery exercise is to validate the ability to failover a production instance from the
              primary data centre to the secondary data centre utilising developed operational and disaster
              recovery procedures and documentation.</p>

            <p>The Covered Services' disaster recovery plans currently have the following target recovery
              objectives: (a) restoration of the Covered Service (recovery time objective) within 12 hours after
              Rotaready's declaration of a disaster; and (b) maximum Customer Data loss (recovery point objective)
              of 4 hours. However, these targets exclude a disaster or multiple disasters causing the compromise
              of both data centres at the same time, and exclude development and test bed environments, such as
              the Sandbox service.</p>

            <H2 uistyle="brand160">Viruses</H2>

            <p>The Covered Services do not scan for viruses that could be included in attachments or other Customer
              Data uploaded into the Covered Services by a customer. Uploaded attachments, however, are not
              executed in the Covered Services and therefore will not damage or compromise the Covered Services by
              virtue of containing a virus.</p>

            <H2 uistyle="brand160">Data Encryption</H2>

            <p>The Covered Services use industry-accepted encryption products to protect Customer Data and
              communications during transmission between a customer's network and the Covered Services and while
              at rest, including 256-bit TLS Certificates and 2048-bit RSA public keys at a minimum. Additionally,
              all data, including Customer Data, is transmitted between data centres for replication purposes
              across a dedicated, encrypted link utilising AES-256 encryption.</p>

            <H2 uistyle="brand160">Return of Customer Data</H2>

            <p>Within 30 days post contract termination, customers may request return of their respective Customer
              Data submitted to the Covered Services (to the extent such data has not been previously deleted by
              Customer). Rotaready shall provide such Customer Data via a downloadable file in comma separated
              value (.csv) format and attachments in their native format.</p>

            <H2 uistyle="brand160">Deletion of Customer Data</H2>

            <p>After termination of all licenses associated with an environment, Customer Data submitted to the
              Covered Services is retained in inactive status within the Covered Services for 30 days and a
              transition period of up to 30 days (which together shall not exceed a total of 60 days), after which
              it is securely overwritten or deleted. Customer Data submitted to the Covered Services (including
              Customer Data retained in inactive status) will be stored on backup for an additional 14 days in
              production environments and 7 days in Sandbox environments, after which it is securely overwritten
              or deleted. Physical media on which Customer Data is stored during the contract term is not removed
              from the data centres that Rotaready uses to host Customer Data unless the media is at the end of
              its useful life or being deprovisioned, in which case the media is first sanitised before removal.
              This process is subject to applicable legal requirements.</p>

            <p>Without limiting the ability for customers to request return of their Customer Data submitted to the
              Covered Services, Rotaready reserves the right to reduce the number of days it retains such data
              after contract termination. Rotaready will update this Security, Privacy and Architecture
              Documentation in the event of such a change.</p>

            <H2 uistyle="brand160">Analytics</H2>

            <p>Rotaready may track and analyse the usage of the Covered Services for purposes of security and
              helping Rotaready improve both the Covered Services and the user experience in using the Covered
              Services. For example, we may use this information to understand and analyse trends or track which
              of our features are used most often to improve product functionality.</p>

            <p>Rotaready may share anonymous usage data with Rotaready's service providers for the purpose of
              helping Rotaready in such tracking, analysis and improvements. Additionally, Rotaready may share
              such anonymous usage data on an aggregate basis in the normal course of operating our business; for
              example, we may share information publicly to show trends about the general use of our services.</p>

            <H2 uistyle="brand160">Interoperation with Other Services</H2>

            <p>The Covered Services may interoperate or integrate with other services provided by Rotaready or third
              parties.</p>
          </PoliciesContentArea>
        </BodyWrapper>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default Page;
